import React from 'react';

export default function FulfillmentPolicy() {
  return (
    <section className="px-5 py-12 md:px-[60px] md:py-16 xlg:px-[330px]">
      <div className="flex flex-col gap-y-12 md:gap-y-16">
        <h1 className="text-[32px] md:text-5xl font-bold text-[#19233F]">
          Fulfillment Policy
        </h1>
        <div className="flex flex-col gap-y-8">
          <p className="text-base font-medium text-[#484352]">
            Please read this document carefully.
          </p>
          <p className="text-base font-medium text-[#484352]">
            This Fulfillment Policy (the "Fulfillment Policy") govern the use of
            www.vitract.com (the "Site"). This Site is owned and operated by
            Vitract Inc. This Site is a health and wellness company. By using
            this Site, you indicate that you have read and understand these
            Fulfillment Policy and agree to abide by them at all times.
          </p>

          <div className="flex flex-col gap-y-4">
            <h3 className="text-base font-bold text-[#020C01]">
              Refund Policy
            </h3>
            <p className="text-base font-medium text-[#484352]">
              We want you to be fully satisfied with your purchase. Refunds are
              available under the following conditions:
            </p>
            <ol className="flex flex-col gap-y-3 list-decimal pl-6">
              <li className="text-base font-medium text-[#484352]">
                If we are unable to deliver your testing kit within 10 days from
                the order date, you may request a full refund. However, delays
                due to circumstances beyond our control, such as extreme weather
                or shipping carrier disruptions, are not eligible for refunds.
              </li>
              <li className="text-base font-medium text-[#484352]">
                If the testing kit arrives damaged (e.g., if the buffer solution
                has spilled), you may request a refund.
              </li>
              <li className="text-base font-medium text-[#484352]">
                Please note that once the test kit has been used or sent back to
                us, refunds are no longer available.
              </li>
            </ol>
          </div>

          <div className="flex flex-col gap-y-4">
            <h3 className="text-base font-bold text-[#020C01]">
              Shipping and Delivery Policy
            </h3>
            <p className="text-base font-medium text-[#484352]">
              We currently only ship within the United States and Canada, and
              shipping is free for all orders in these regions.
            </p>
            <ul className="flex flex-col gap-y-4 list-disc pl-6">
              <li className="text-base font-medium text-[#484352]">
                Orders are shipped within 1-2 business days of purchase, with
                delivery times typically ranging from 3-7 business days
                depending on your location.
              </li>
              <li className="text-base font-medium text-[#484352]">
                Delivery times may vary, and we are not responsible for delays
                due to factors outside our control, such as shipping carrier
                issues or natural events.
              </li>
            </ul>
          </div>

          <div className="flex flex-col gap-y-4">
            <h3 className="text-base font-bold text-[#020C01]">
              Return Policy and Process
            </h3>
            <p className="text-base font-medium text-[#484352]">
              We accept returns under the following conditions:
            </p>
            <ol className="flex flex-col gap-y-3 list-decimal pl-6">
              <li className="text-base font-medium text-[#484352]">
                Returns are accepted if the test kit arrives damaged (e.g.,
                buffer spilled)
              </li>
              <li className="text-base font-medium text-[#484352]">
                The kit must be unused and returned in its original condition.
              </li>
              <li className="text-base font-medium text-[#484352]">
                To initiate a return, contact our support team at [insert
                email/contact information]. Our team will provide a return
                authorization and instructions for the return process.
              </li>
            </ol>
          </div>

          <p className="text-base font-medium text-[#020C01]">
            For lost or misplaced kits, we offer a replacement kit for a $50
            fee.
          </p>

          <div className="flex flex-col gap-y-4">
            <h3 className="text-base font-bold text-[#020C01]">
              Cancellation Policy
            </h3>
            <p className="text-base font-medium text-[#484352]">
              Cancellations are only permitted under the conditions outlined in
              our Refund Policy. If your order qualifies for a refund based on
              our policy, you may request a cancellation through our support
              team.
            </p>
          </div>

          <div className="flex flex-col gap-y-4">
            <h3 className="text-base font-bold text-[#020C01]">
              Contact Information
            </h3>
            <p className="text-base font-medium text-[#484352]">
              If you have any questions, concerns or complaints, you can contact
              us at {` `}
              <a
                href="mailto:hello@vitract.com"
                className="underline text-[#FF713F]"
              >
                hello@vitract.com
              </a>
            </p>
          </div>

          <p className="text-base font-medium text-[#020C01]">
            We are here to support you with any questions regarding your order
            and to ensure your experience is smooth and satisfactory.
          </p>
        </div>
      </div>
    </section>
  );
}
