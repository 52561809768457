import React from 'react';
import Layout from '@/layouts/main';
import FulfillmentPolicy from '@/views/fulfillment-policy';
import { Mixpanel } from '@/utils';

export default function OurFulfillmentPolicy() {
  React.useEffect(() => Mixpanel.track(`View Fulfillment Policy Page`), []);
  return (
    <Layout
      title="Fulfillment Policy - Transforming your health through your gut"
      description="We aim to reduce over-dependence on pills by using good food to better the health
outcomes of people living with anxiety and depression."
      active="fulfillment-policy"
    >
      <FulfillmentPolicy />
    </Layout>
  );
}
